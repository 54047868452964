import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CardComponent } from './components/card/card.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { ModalComponent } from './components/modal/modal.component';
import { PaginationsComponent } from './components/paginations/paginations.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { UiModalComponent } from './components/ui-modal/ui-modal.component';
import { ErrorHandlerDirective } from './directives/error-handler.directive';
import { ManageTransactionsDirective } from './directives/manage-transactions.directive';
import { ContentSlicePipe } from './pipes/content-slice.pipe';
import { SearchPipe } from './pipes/search.pipe';



@NgModule({
  declarations: [
    CardComponent,
    ConfigurationComponent,
    ModalComponent,
    PaginationsComponent,
    SpinnerComponent,
    UiModalComponent,
    SearchPipe,
    ManageTransactionsDirective,
    ErrorHandlerDirective,
    ContentSlicePipe,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
    CardComponent,
    ConfigurationComponent,
    ModalComponent,
    PaginationsComponent,
    SpinnerComponent,
    UiModalComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SearchPipe,
    ManageTransactionsDirective,
    ErrorHandlerDirective,
    ContentSlicePipe,
    RouterModule,
    SidebarComponent

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
