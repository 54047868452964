import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
const chainId = environment.CHAIN_ID
/**
 * Common helper functions
 */
export class CommonHelper {
  /**
   * File Download
   * @param {string} path
   * @param {string} fileName
   */
  public fileDownload(path: string, fileName: string) {
    const account = localStorage.getItem('wagmi.store') === null ?
{ address: '', network: '', chainId: '', provider: '' } :
JSON.parse(localStorage.getItem('wagmi.store') as any);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', environment[account.state.data.chain.id].FILE_PATH + path);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  /**
   * Calculates the similarity between two strings using the Levenshtein distance algorithm.
   *
   * @param {string} a - The first string to compare.
   * @param {string} b - The second string to compare.
   * @returns {number} The similarity score between 0 and 1, where 1 means identical strings.
   */
  stringSimilarity(a, b) {
    const distance = this.levenshteinDistance(a, b);
    const maxLen = Math.max(a.length, b.length);
    return (maxLen - distance) / maxLen;
  }
  
  /**
   * Computes the Levenshtein distance between two strings, which is a measure of the minimum number of single-character edits required to change one word into the other.
   *
   * @param {string} a - The first string.
   * @param {string} b - The second string.
   * @returns {number} The Levenshtein distance between the two strings.
   */
  levenshteinDistance(a, b) {
    const matrix = [];

    // Initialize the first row and column of the matrix.
    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }
    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    // Populate the matrix.
    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        if (b[i - 1] === a[j - 1]) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1, // deletion
          );
        }
      }
    }

    return matrix[b.length][a.length];
  }

  /**
   * Validates the email pattern for a given FormControl.
   *
   * @param {FormControl} control - The form control containing the email to validate.
   */
  emailPatternValidation(control: FormControl) {
    const patternRegex = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (patternRegex.test(control.value)) {
    } else {
      return { pattern: true };
    }
  }
}
