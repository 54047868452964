import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

/**
 * Idle Service for auto logout if the time(which has been set in environment) expires
 */
export class IdleService {
  private sessionToken = '';
  private adminId = '';
  private expiryTime = '';



  /**
   * constructor
   */
  constructor(
    private idle: Idle,
    private storageService: StorageService,
    private router: Router,
  ) {
    this.idle.setIdle(environment.AUTO_LOGOUT_DURATION);
    this.idle.setTimeout(environment.AUTO_LOGOUT_TIMEOUT);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => this.reset());
    this.idle.onIdleStart.subscribe(() => {
    });
    /** Backup to stop idle timer if still running while user is not logged in (haven't seen this be needed yet) */
    this.idle.onTimeoutWarning.subscribe(() => {
      if (!this.isLoggedIn) {
        this.idle.stop();
      }
    });

    /** called after the inactive timeout */
    this.idle.onTimeout.subscribe(() => {
      this.storageService.clearItem();
    });
    if (this.isLoggedIn) {
      this.idle.watch();
    }
    this.checkSessionValidity();
  }

  private get isLoggedIn(): boolean {
    this.sessionToken = this.storageService.getItem('token') || '';
    this.adminId = this.storageService.getItem('adminId') || '';
    return !!this.sessionToken && !!this.adminId;
  }

  private checkSessionValidity(): void {
    const expiryTimeString = this.storageService.getItem('expiryTime');
    if (expiryTimeString) {
      this.expiryTime = JSON.parse(expiryTimeString);
    } else {
      this.expiryTime = '{}'; // or set it to null or a default value
    }
    const expirationDate = this.expiryTime;

    // Ensure the token expiration date exists
    if (expirationDate) {
      const currentTime = new Date().getTime();
      const expTime = new Date(expirationDate).getTime();
      console.log(currentTime, expTime);

      // Check if the token is expired
      if (expTime <= currentTime) {
        this.logoutAdmin();
      }
    }
  }


  /**
   * start idle if logged in & not running.
   */
  public reset(): void {
    if (this.isLoggedIn && !this.idle.isRunning()) {
      this.idle.watch();
    }
  }

  /**
   * logout the admin
   */
  logoutAdmin() {
    this.storageService.clearItem();
    this.idle.stop();
    this.router.navigate(['/login']);
  }


}