// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  PINATA_KEY: '3816585697981d38d50d',
  PINATA_SECRET: '4722d4967331aafb013d9dab2bc6e3534e654be9a1cbc8ce4aa4adaec3c13f27',
  BASE_URL: 'https://chocolate-magnificent-blackbird-410.mypinata.cloud/ipfs/',
  API_BASE_URL: 'https://devapi.realworld.fi/api/v1',
  API_BASE_URL_V2: 'https://devapi.realworld.fi/api/v2',
  SUPPORTED_CHAINS: ['80002'],
  CHAIN_ID: '0x13882',
  WEB_SITE_URL: 'https://devapp.realworld.fi',
  WALLET_CONNECT_PROJECT_ID: "b6c201630414d0fd586cfc6add82cde7",
  BUG_ATLAS_API_KEY: 'CIEW9TJB9KBZE83I',
  BUG_ATLAS_SECRET_KEY: 'HEIZT5FYN5SER86Z',

  '80002': {
    TRANSH_URL: 'https://www.oklink.com/amoy/tx/',
    EXPLORER_URL: 'https://www.oklink.com/amoy',
    FACTORY_ADDRESS: '0x12ba55372ec48125e35dE6b0a84866b681276Bf7',
    ACCESS_CONTROL_TOKEN: '0xDDE9256778596E949DEa33912c82312B1cd4066C',
    PROVIDER: 'https://rpc-amoy.polygon.technology',
    CHAIN_NETWORK: '0x13882',
    CHAINID: '80002',
    CURRENCY_NAME: 'MATIC',
    CURRENCY_SYMBOL: 'MATIC',
    CHAIN_NAME: 'Polygon Test Network',
    FILE_PATH: '',
    BORROW_LEND_CONTRACT: '0x27cB8E823B7e357B62DE0B4985dbf3261fAbEd51',
    EXCHANGE_CONTRACT: '0xE986659879107F408325120D0a154910545079C2',
    LAZYMINT_CONTRACT: '0x692D575C4F4753ec9aB7858E27a28092944F2cb0',
    ERC721_CONTRACT: '0x18c9a0EFF3C0394440De38acB1C63ABf6ad5B542',
    NFT_LOAN_CONTRACT_TEST: "0x94c9Fc969F2D83B95CEeD4B5Ce503BB14aB93200", //TESTING IN HOURS BASED;
    NFT_LOAN_CONTRACT_DEV: "0xdAf8940bc70e121D22657Ae91920e5FD984aC290", //PRODUCTION 
    PLATFORM_FEE: "0x577b282078912c354b3f4b503c97Bf2770eCe2cC"
  },
  // Idle
  AUTO_LOGOUT_DURATION: 21600, //(six hours) In seconds; how long for inactive modal to appear
  AUTO_LOGOUT_TIMEOUT: 30, // In seconds; how long for user to act after inactive modal appears before being logged out.
};
