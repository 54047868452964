<router-outlet>
  <app-spinner></app-spinner>
</router-outlet>

<!-- Session Expiry Modal -->
<app-ui-modal #sessionExpiryModal [hideHeader]="true" [hideFooter]="true">
  <div class="app-modal-body">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sessionExpiryModal">Warning!</h5>
          <button type="button" class="btn-close" (click)="sessionExpiryModal.hide()"></button>
        </div>
        <div class="modal-body mb-2">
          <div>
            {{confirmationData}}
          </div>
        </div>
      </div>
    </div>

  </div>
</app-ui-modal>