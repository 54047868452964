import { DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDatepickerModule, NgbDropdownModule, NgbModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleModule } from '@ng-idle/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { TagInputModule } from 'ngx-chips';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Const } from './core/const/const';
import { RegulatedInterceptor } from './core/interceptors/regulated.interceptor';
import { AdminComponent } from './shared/components/nav/admin.component';
import { NavBarComponent } from './shared/components/nav/nav-bar/nav-bar.component';
import { NavRightComponent } from './shared/components/nav/nav-bar/nav-right/nav-right.component';
import { NavCollapseComponent } from './shared/components/nav/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavContentComponent } from './shared/components/nav/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './shared/components/nav/navigation/nav-content/nav-group/nav-group.component';
import { NavItemComponent } from './shared/components/nav/navigation/nav-content/nav-item/nav-item.component';
import { NavigationItem } from './shared/components/nav/navigation/navigation';
import { NavigationComponent } from './shared/components/nav/navigation/navigation.component';
import { ErrorHandlerDirective } from './shared/directives/error-handler.directive';
import { ManageTransactionsDirective } from './shared/directives/manage-transactions.directive';
import { CommonHelper } from './shared/helpers/common-helper';
import { SharedModule } from './shared/shared.module';

@NgModule({
    declarations: [
        AppComponent,
        AdminComponent,
        NavigationComponent,
        NavContentComponent,
        NavGroupComponent,
        NavCollapseComponent,
        NavItemComponent,
        NavBarComponent,
        NavRightComponent,

    ],
    imports: [
        SharedModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        // CardComponent,
        NgbDropdownModule,
        NgbTooltipModule,
        NgbModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        DataTablesModule,
        TagInputModule,
        ReactiveFormsModule,
        FormsModule,
        NgbDatepickerModule,
        NgSelectModule,
        ClipboardModule,
        NgbPaginationModule,
        NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' }),
        NgIdleModule.forRoot(),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: RegulatedInterceptor, multi: true },
        NavigationItem, CommonHelper, Const, DecimalPipe, ManageTransactionsDirective, ErrorHandlerDirective],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
/**
 * Export Class
 */
export class AppModule {

}
