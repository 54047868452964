import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginService } from './services/login.service';
import { AdminComponent } from './shared/components/nav/admin.component';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
        pathMatch: 'full'
    },
    {
        path: '',
        component: AdminComponent,
        canActivate: [LoginService],
        children: [
            {
                path: '',
                redirectTo: 'fees-management',
                pathMatch: 'full'
            },
            {
                path: 'fees-management',
                loadChildren: () => import('./pages/fees-management/fees-management.module').then(m => m.FeesManagementModule),
            },
            {
                path: 'email',
                loadChildren: () => import('./pages/email-management/email-management.module').then(m => m.EmailManagementModule),
            },
            {
                path: 'market-place',
                loadChildren: () => import('./pages/market-place-management/market-place-management.module').then(m => m.MarketPlaceManagementModule),
            },
            {
                path: 'whitelist-management',
                loadChildren: () => import('./pages/whitelist-management/whitelist-management.module').then(m => m.WhitelistManagementModule),
            },
            {
                path: 'nft-trait',
                loadChildren: () => import('./pages/nft-trait/nft-trait.module').then(m => m.NftTraitModule),
            },
            {
                path: 'collections-management',
                loadChildren: () => import('./pages/collections-management/collections-management.module').then(m => m.CollectionsManagementModule),
            },

            {
                path: 'faq',
                loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule),
            },

            {
                path: 'fees-management/search-collections',
                loadChildren: () => import('./pages/search-collections/search-collections.module').then(m => m.SearchCollectionsModule),
            },
            {
                path: 'fees-management/edit-minting-fee',
                loadChildren: () => import('./pages/edit-platform-fee/edit-platform-fee.module').then(m => m.EditPlatformFeeModule),
            },
            {
                path: 'fees-management/edit-royalty-fee',
                loadChildren: () => import('./pages/edit-royalty-fee/edit-royalty-fee.module').then(m => m.EditRoyaltyFeeModule),
            },

            {
                path: 'settings',
                loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
            },
            {
                path: 'website-visitors',
                loadChildren: () => import('./pages/email-subscription/email-subscription.module').then(m => m.EmailSubscriptionModule),
            },
            {
                path: '**',
                loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule),
            }
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
/**
 * Export Class
 */
export class AppRoutingModule { }
