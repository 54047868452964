import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { navItems } from './nav-data';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],

})
export class SidebarComponent {
    navData = navItems;

    /**
     * constructor
     */
    constructor(private router: Router) { }

    /**
     * check if the route is active
     */
    isActive(nav: any): boolean {
        const currentRoute = this.router.url;
        if (nav.children) {
            return nav.children.some(child => currentRoute.includes(child.route));
        }
        return false;
    }
}
