<div class="collapse navbar-collapse" [ngClass]="this.collapseStyle==='block'?'show-view':'d-none'"
  [style.display]="this.collapseStyle">

  <div
    [ngClass]="this.collapseStyle==='block'?'show-view-ls d-flex flex-wrap gap-3 align-items-center':'d-flex align-items-center'">
    <div class="regulated">
      <div class="d-flex gap-2">
        <p class="tooltips d-flex align-items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
            <path
              d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
          </svg>

          <span class="loan-details-tooltip ms-2">Toggle between the Custodial. and Self-Custody. environments</span>
          <ng-container class="">
            Self-Custody.
            <label class="switch ml-4">
              <input type="checkbox" (change)="slideToggle()" [checked]="!regulated">
              <span class="slider round"></span>
            </label>
          </ng-container>
      </div>
    </div>
    <ul class="navbar-nav ml-auto mr-4">
      <li class="d-flex align-items-center ml-4">
        <w3m-button balance='hide'></w3m-button>
      </li>
    </ul>
  </div>


  <app-ui-modal #switchNetworkModal [hideHeader]="true" [hideFooter]="true">
    <div class="app-modal-body">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row my-4">
              <div class="col-lg-12">
                <div class="text-center">
                  <div class="mb-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#ff0000" viewBox="0 0 16 16">
                      <path
                        d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                      <path
                        d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                    </svg>
                  </div>
                  <h4>Incorrect Network.</h4>
                  <p class="text-center">Please change your Network to use our admin application.</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <ng-container *ngFor="let data of defaultNetwork">
                  <ng-container *ngIf="walletProvider === 'io.metamask'">
                    <div class="sign-in-wallet">
                      <div class="d-flex gap-2">
                        <p style="color: #fff;" (click)="switchNetwork(data)" *ngIf="data === '80002'">Switch to Polygon
                          Amoy</p>
                        <p style="color: #fff;" (click)="switchNetwork(data)" *ngIf="data === '137'">Switch to Polygon
                        </p>
                        <img src="assets/images/polygon.webp" width="30" alt="polygon">
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <div *ngIf="walletProvider !== 'io.metamask'">
                  <p class="mb-3">Unsupported chain. Logout and change network to access application</p>
                  <div class="sign-in-wallet" (click)="logout()">
                    <p>Log out.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-ui-modal>